
  import Vue from 'vue'
  import { ethers } from 'ethers'
  import { Client, WriteStorageObject } from "@heroiclabs/nakama-js"
  import { providers } from '@0xsequence/multicall'
  import { delay, toFloat } from '@/utils'
  import { IEcosystem, EcosystemId, ChainId } from '@/ecosystem'
  import {
    PADSWAP_PAIR_ABI,
    ERC20_ABI,
  } from '../constants'


  // @ts-ignore:disable-next-line
  import VueCryptojs from 'vue-cryptojs';
  Vue.use(VueCryptojs)

  export default Vue.extend({
    data () {
      return {
        active: <boolean> true,
        toadMarketCap: <number> 0,
        padMarketCap: <number> 0,

        client: <any> null,
        session: <any> null,
        account: <any> null,

        gameSrc: <string> "./memeguardians-executable/index.html",
        iframeSrc: <string> "./memeguardians-executable/index.html"
      }
    },
    created () {
      this.$store.commit('setEcosystemId', 2)
      setInterval(async () => {
        await this.updateWalletData()
      }, 1000)
    },
    computed: {
      ecosystemId: {
        get(): EcosystemId {
          return this.$store.state.ecosystemId
        },
        set(val: EcosystemId) {
          this.$store.commit('setEcosystemId', val)
        }
      },
      address(): string {
        return this.$store.state.address
      },
      multicall(): ethers.providers.Provider {
        return this.$store.getters.multicall
      },
      ecosystem(): IEcosystem {
        return this.$store.getters.ecosystem
      },
      priceModel() : any {
        return this. $store.getters.ecosystem.priceModel
      },
      web3(): ethers.Signer | null {
        return this.$store.state.web3
      },
      chainId(): ChainId {
        return this.$store.getters.ecosystem.chainId
      },
      chainName(): string {
        return this.$store.getters.ecosystem.routeName
      },
    },
    beforeRouteLeave (to, from, next) {
      next()
    },
    methods: {
      async updateWalletData() {
        // TODO: Encrypt the data before sending

        var data : any = {
          "walletAddress": this.address
        }

        const moonbeamToadAddress = "0xf480f38c366daac4305dc484b2ad7a496ff00cea"
        const moonbeamPadAddress = "0x59193512877e2ec3bb27c178a8888cfac62fb32d"

        const padContract = new ethers.Contract(moonbeamPadAddress, ERC20_ABI, this.multicall)
        const toadContract = new ethers.Contract(moonbeamToadAddress, ERC20_ABI, this.multicall)

        const padAmount = await padContract.balanceOf(this.address)
        const toadAmount = await toadContract.balanceOf(this.address)

        data["tokens"] = {
          "TOAD": toFloat(toadAmount),
          "PAD": toFloat(padAmount)
        }

        this.iframeSrc = this.gameSrc + "#" + JSON.stringify(data)
      },
      round(num : any, dec : any) {
        num = Number(num).toFixed(20)
        if(!Number.isFinite(Number(num))) num = '0.0'
        num = Number(num).toFixed(20)
        const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${dec}})?`)
        let [int, decimals] = num.toString().replace(',', '.').split('.')
        if(dec == 0) return int
        const rounded = num.toString().match(regex)[0]
        return rounded
      },
    },
  })
